<template>
    <section class="carrousel-switch my-10 md:my-18">
        <carousel v-model="currentSlideId" :settings="settings">
            <slide
                v-for="(slide, key) in slides"
                :key="key"
            >
                <div class="flex justify-center px-6">
                    <img
                        :src="slide.image.url"
                        :alt="slide.image.alt"
                        :width="slide.image.width"
                        :height="slide.image.height"
                    >
                </div>
            </slide>
        </carousel>

        <div class="flex justify-center mt-10 md:mt-12">
            <Switch :label-left="slides[0].heading" :label-right="slides[1].heading" v-model="isChecked"></Switch>
        </div>
    </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import Switch from "@/app/components/Switch";

export default {
    name: "CarrouselSwitch",

    props: {
        slides: {
            type: Array,
            required: true,
        },
        ariaLabelNavigation: {
            type: String,
            required: true,
        },
    },

    components: {
        Carousel,
        Slide,
        Switch,
    },

    data() {
        return {
            currentSlideId: 0,
            settings: {
                itemsToShow: 1,
                transition: 400,
                mouseDrag: false,
                touchDrag: false,
            },
            isChecked: false,
        }
    },

    watch: {
        isChecked(newVal) {
            this.currentSlideId = newVal | 0;
        }
    },
}
</script>

<style scoped lang="scss">
    .carrousel-switch {
        &__button {
            &--active {
                &::before {
                    content: '';
                    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 md:w-16 md:h-16 border-2 md:border-4 border-red rounded-full;
                }
            }
        }
    }
</style>
