<template>
    <section :class="hasParent ? 'slider-reveal--has-parent': 'mt-10 mt-16 md:mt-18 md:mb-32'" class="slider-reveal relative">
        <div :class="hasParent ? 'slider-reveal__container lg:absolute lg:right-0': 'grid-container'">
            <img-comparison-slider class="slider-reveal__slider focus:outline-none">
                <img slot="first" :src="imageLeft.url" :alt="imageLeft.alt" :width="imageLeft.width" :height="imageLeft.height">
                <img slot="second" :src="imageRight.url" :alt="imageRight.alt" :width="imageRight.width" :height="imageRight.height">

                <div slot="handle">
                    <button :aria-label="btnAriaLabel" class="slider-reveal__btn w-12 h-12 md:w-16 md:h-16 bg-cover bg-center"></button>
                </div>
            </img-comparison-slider>
        </div>
    </section>
</template>

<script>
import { ImgComparisonSlider } from '@img-comparison-slider/vue';

export default {
    name: "SliderReveal",

    props: {
        imageLeft: {
            type: Object,
            required: true,
        },
        imageRight: {
            type: Object,
            required: true,
        },
        hasParent: {
            type: Boolean,
            required: true,
        },
        btnAriaLabel: {
            type: String,
            required: true,
        },
    },

    components: {
        ImgComparisonSlider,
    },
}
</script>

<style scoped lang="scss">
    .slider-reveal {
        &__container {
            @screen lg {
                width: calc(920vw*100/1440);
            }
        }

        &__btn {
            margin-top: 40vw;
            background-image: url('~@/static/images/pictos/slider.svg');

            @screen lg {
                margin-top: 35vw;
            }
        }

        &--has-parent {
            @screen lg {
                height: calc(620vw*100/1440);
            }

            .slider-reveal__btn {
                @screen lg {
                    margin-top: 30vw;
                }
            }
        }
    }
</style>
