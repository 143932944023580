<template>
    <div class="header-nav w-screen z-50">
        <header v-if="!isFixed" :class="hasDarkBackground ? 'bg-blue-dark py-4 md:py-6': 'absolute pt-4 md:pt-6'" class="flex justify-center w-full">
            <a :href="siteUrl" :title="homepageLinkTitle">
                <img
                    :src="brandLogo.url"
                    :alt="brandLogo.alt"
                    class="h-9 md:h-auto"
                >
            </a>
        </header>
        <slide>
            <header
                v-if="isFixed"
                class="fixed justify-between items-center top-0 flex w-full py-3 px-4 md:py-2.5 md:px-14 bg-blue-dark"
                :aria-label="ariaLabels.mainMenu"
            >
                <nav class="flex items-center">
                    <a :href="siteUrl" :title="homepageLinkTitle">
                        <img :src="brandLogo.url" :alt="brandLogo.alt" class="h-9 md:h-auto">
                    </a>
                    <span class="hidden lg:block w-px h-7 bg-white opacity-25 mx-3 md:mx-6"></span>

                    <a
                        v-for="(item, key) in mainLinks"
                        :key="key"
                        :href="item.link.url"
                        :target="item.link.target"
                        :title="item.link.title"
                        :aria-label="item.link.ariaLabel"
                        :class="currentUrl === item.link.url ? 'opacity-100' : 'opacity-40 hover:opacity-100 transition-opacity duration-200'"
                        class="hidden lg:block mr-8"
                    >
                        <img v-if="item.logo" :src="item.logo.url" :alt="item.logo.alt" class="h-2">
                        <span v-else class="header-nav__featured-link font-medium uppercase text-white">{{ item.link.label }}</span>
                    </a>
                </nav>

                <div class="flex items-center">
                    <nav class="hidden lg:flex text-white">
                        <a
                            v-for="(featuredLink, key) in featuredLinks"
                            :href="featuredLink.url"
                            :target="featuredLink.target"
                            :title="featuredLink.title"
                            :aria-label="featuredLink.ariaLabel"
                            :key="key"
                            class="header-nav__featured-link font-medium uppercase mx-3"
                        >
                            {{ featuredLink.label }}
                        </a>
                    </nav>

                    <button
                        class="header-nav__burger-btn w-6 h-6 md:w-8 md:h-8 ml-7 bg-center bg-cover" @click="toggleMenu"
                        :aria-label="ariaLabels.openMenu"
                    ></button>
                </div>
            </header>
        </slide>

        <fade>
            <header-menu
                v-if="isMenuOpened"
                :main-links-heading="mainLinksHeading"
                :main-links="mainLinks"
                :featured-links="featuredLinks"
                :links="links"
                :aria-label-close="ariaLabels.closeMenu"
                :languages="languages"
                @close="toggleMenu"
            >
                <slot name="mainlogo"></slot>
            </header-menu>
        </fade>
    </div>
</template>

<script>
import Slide from '@/app/transitions/Slide';
import Fade from '@/app/transitions/Fade';
import HeaderMenu from "@/app/components/HeaderMenu";

export default {
    name: "HeaderNav",

    props: {
        mainLinksHeading: {
            type: String,
            default: null,
        },
        mainLinks: {
            type: Array,
            required: true,
        },
        featuredLinks: {
            type: Array,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
        languages: {
            type: Array,
            required: true,
        },
        hasDarkBackground: {
            type: Boolean,
            default: false,
        },
        brandLogo: {
            type: Object,
            required: true,
        },
        siteUrl: {
            type: String,
            required: true,
        },
        homepageLinkTitle: {
            type: String,
            required: true,
        },
        currentUrl: {
            type: String,
            required: true,
        },
    },

    components: {
        Slide,
        Fade,
        HeaderMenu,
    },

    data() {
        return {
            isFixed: false,
            isMenuOpened: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    },

    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        scrollHandler() {
            this.isFixed = window.scrollY >= 250;
        },

        toggleMenu() {
            this.isMenuOpened = !this.isMenuOpened;
            document.body.style.overflow = this.isMenuOpened ? 'hidden': 'visible';
        },
    }
}
</script>

<style scoped lang="scss">
    .header-nav {
        &__featured-link {
            font-size: 0.6875rem;
            letter-spacing: 0.59px;
        }

        &__burger-btn {
            background-image: url('~@/static/images/pictos/burger.svg');
        }
    }
</style>
