<template>
    <transition name="switch-content" mode="out-in">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: "SwitchKeyFigureContent"
    }
</script>

<style scoped lang="scss">
    .switch-content-enter-active, .switch-content-leave-active {
        transition: opacity 300ms ease-in-out;

        :deep(.animated-key-figure) {
            transition: transform 300ms, width 200ms;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
        }
    }
    .switch-content-enter-from, .switch-content-leave-to {
        opacity: 0;

        :deep(.animated-key-figure) {
            transform: translateX(10px);
            width: 0;
        }
    }
</style>
