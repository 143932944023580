<template>
    <section class="carousel-media py-10 md:py-16 bg-gray-96 overflow-hidden">
        <div class="grid-container">
            <carousel ref="carrousel" v-model="currentItemId" :settings="settings">
                <slide v-for="(item, key) in items" :key="key">
                    <div class="px-1 md:px-2">
                        <img :src="item.image.url" :alt="item.image.alt">
                    </div>
                </slide>
            </carousel>

            <div class="flex justify-center items-center mt-6 md:mt-10">
                <button
                    class="arrow-btn arrow-btn--previous"
                    :disabled="currentItemId === 0"
                    :aria-label="ariaLabels.previous"
                    @click="$refs.carrousel.prev()"
                ></button>
                <span class="h3 mx-6 md:mx-8">{{ currentItemId+1 }} / {{ items.length }}</span>
                <button
                    class="arrow-btn"
                    :disabled="currentItemId >= items.length-1"
                    :aria-label="ariaLabels.next"
                    @click="$refs.carrousel.next()"
                ></button>
            </div>
        </div>
    </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
export default {
    name: "CarrouselMedia",

    props: {
        items: {
            type: Array,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
    },

    components: {
        Carousel,
        Slide,
    },

    data() {
        return {
            currentItemId: 0,
            settings: {
                itemsToShow: 1,
                breakpoints: {
                    768: {
                        itemsToShow: 1.2,
                    },
                    1024: {
                        itemsToShow: 1.4,
                    }
                }
            }
        }
    },
}
</script>

<style scoped lang="scss">
    .carousel-media {
        .carousel :deep(.carousel__viewport) {
            overflow: visible;
        }
    }
</style>
