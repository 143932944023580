<template>
    <intersector :threshold="[1.0]" @enter="playIn">
        <div ref="root" class="hero-animations relative">
            <slot></slot>
            <div ref="filet" class="absolute top-full left-1/2 w-px bg-white opacity-20"></div>
        </div>
    </intersector>
</template>

<script>
import Intersector from "@/app/components/Intersector";
import { gsap } from "gsap";

export default {
    name: "HeroAnimations",

    components: {
        Intersector,
    },

    data() {
        return {
            elements: null,
        }
    },

    mounted() {
        this.elements = this.$refs.root.querySelectorAll('.hero-animated-element');

        gsap.set(this.elements, {
            y: 35,
            autoAlpha: 0,
            force3D: true,
        });
    },

    methods: {
        playIn() {
            const tl = new gsap.timeline();

            tl.to(this.elements, {
                y: 0,
                autoAlpha: 1,
                ease: 'power2.out',
                stagger: 0.2,
                delay: 0.4,
                force3D: true,
            });

            tl.to(this.$refs.filet, {
                height: window.innerHeight/2,
                ease: 'power1.in',
                duration: 1.5,
            });
        },
    },
}
</script>
