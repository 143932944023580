require('typeface-montserrat');
import "./assets/styles/main.scss";
import 'vue3-carousel/dist/carousel.css';

import { createApp } from 'vue';
import CsrfInput from "@/app/components/CsrfInput";
import HeaderNav from "@/app/components/HeaderNav";
import VideoBackground from 'vue-responsive-video-background-player';
import HeroAnimations from "@/app/components/HeroAnimations";
import SectionHeader from "@/app/components/SectionHeader";
import CarrouselSwitch from "@/app/components/CarrouselSwitch";
import CarrouselMedia from "@/app/components/CarrouselMedia";
import CarrouselAndText from "@/app/components/CarrouselAndText";
import SliderReveal from "@/app/components/SliderReveal";
import KeyFigureSwitch from "@/app/components/KeyFigureSwitch";
import KnotsAnimation from "@/app/components/KnotsAnimation";
import CustomVideoPlayer from "@/app/components/CustomVideoPlayer";
import CarrouselVirtualTour from "@/app/components/CarrouselVirtualTour";
import SlideoutPanel from "@/app/components/SlideoutPanel";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        VideoBackground,
        HeaderNav,
        HeroAnimations,
        SectionHeader,
        CarrouselSwitch,
        CarrouselMedia,
        CarrouselAndText,
        SliderReveal,
        KeyFigureSwitch,
        KnotsAnimation,
        CustomVideoPlayer,
        CarrouselVirtualTour,
        SlideoutPanel,
    }
}).mount('#app');
