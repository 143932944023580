<template>
    <label class="switch flex justify-center items-center relative cursor-pointer">
        <span :class="modelValue ? 'text-gray-48': 'text-red'" class="h3 transition-colors duration-200">{{ labelLeft }}</span>
        <input
            class="switch__input opacity-0 absolute"
            type="checkbox"
            :checked="modelValue"
            @input="updateValue"
        >
        <span :class="{'switch__checkbox--checked': modelValue}" class="switch__checkbox flex items-center w-14 h-7 md:w-20 md:h-10 px-1 md:px-2 rounded-full border border-gray-48 mx-4 md:mx-6"></span>
        <span :class="modelValue ? 'text-red': 'text-gray-48'" class="h3 transition-colors duration-200">{{ labelRight }}</span>
    </label>
</template>

<script>
export default {
    name: "Switch",

    props: {
        labelLeft: {
            type: String,
            default: '',
        },
        labelRight: {
            type: String,
            default: '',
        },
        modelValue: {},
    },

    emits: ['update:modelValue'],

    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.checked);
        },
    }
}
</script>

<style scoped lang="scss">
    .switch {
        &__input:focus + &__checkbox {
            @apply ring-2 ring-gray-68;
        }

        &__checkbox {
            @apply transition-all duration-200;

            &::after {
                content: '';
                @apply bg-red w-4 h-4 md:w-6 md:h-6 rounded-full transition-transform duration-200;
            }

            &--checked {
                &::after {
                    transform: translateX(30px);

                    @screen md {
                        transform: translateX(38px);
                    }
                }
            }
        }
    }
</style>
