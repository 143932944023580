<template>
    <div class="fixed w-screen h-screen bg-overlay" @click="$emit('close')">
        <div ref="nav" class="header-menu__nav absolute top-0 right-0 bottom-0 h-screen w-full bg-blue-dark overflow-auto pb-20" @click.stop>
            <div ref="head" class="flex justify-between items-center md:justify-end pt-4 px-6 md:pt-8 md:px-14">
                <div class="md:hidden">
                    <slot></slot>
                </div>
                <button
                    class="header-menu__cross-btn w-6 h-6 md:w-8 md:h-8 bg-center bg-cover"
                    :aria-label="ariaLabelClose"
                    @click="$emit('close')"
                ></button>
            </div>

            <nav class="flex flex-col items-start pt-12 px-14 md:pl-23 md:pr-14">
                <ol v-if="links.length" class="w-full list-none border-b border-white/20 mb-10">
                    <li ref="links" v-for="(link, key) in links" :key="key" class="button-text flex items-center mb-14">
                        <span class="header-menu__list-counter inline-flex items-center text-red">
                            {{ key+1 }}
                        </span>
                        <a
                            :href="link.url"
                            :target="link.target ?? '_self'"
                            class="header-menu__list-link text-white transition-opacity duration-200 hover:opacity-60"
                            @click.prevent="anchorClickHandler"
                        >{{ link.label }}</a>
                    </li>
                </ol>

                <div ref="mainLinks" v-if="mainLinks.length" class="flex flex-col mb-10">
                    <p class="overline text-white">
                        {{ mainLinksHeading }}
                    </p>

                    <ul class="flex flex-wrap">
                        <li v-for="(item, key) in mainLinks" :key="key" class="my-1 mr-8">
                            <a
                                :href="item.link.url"
                                :target="item.link.target"
                                :title="item.link.title"
                                :aria-label="item.link.ariaLabel"
                                class="chevron-link"
                            >
                                <img v-if="item.logo" :src="item.logo.url" :alt="item.logo.alt" class="h-1.5">
                                <span v-else class="text-white">{{ item.link.label }}</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <a
                    ref="featuredLinks"
                    v-for="(featuredLink, key) in featuredLinks"
                    :href="featuredLink.url"
                    :target="featuredLink.target"
                    :title="featuredLink.title"
                    :aria-label="featuredLink.ariaLabel"
                    :key="key"
                    class="btn btn--ghost-white mb-6"
                >
                    {{ featuredLink.label }}
                </a>

                <ul
                    ref="languages"
                    class="list-none flex flex-wrap mt-4 md:mt-10 text-white button-text"
                >
                    <li
                        v-for="(language, key) in languages"
                        :key="key"
                        :class="{'border-b border-white pb-1 mb-2': language.current}"
                        class="mr-6"
                    >
                        <a class="transition-opacity duration-200 hover:opacity-60" :href="language.url">{{ language.label }}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin);

export default {
    name: "HeaderMenu",

    props: {
        mainLinksHeading: {
            type: String,
            default: null,
        },
        mainLinks: {
            type: Array,
            required: true,
        },
        featuredLinks: {
            type: Array,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
        ariaLabelClose: {
            type: String,
            required: true,
        },
        languages: {
            type: Array,
            required: true,
        },
    },

    emits: ['close'],

    mounted() {
        this.playIn();
    },

    methods: {
        playIn() {
            const tl = new gsap.timeline();

            tl.from(this.$refs.nav, {
                x: this.$refs.nav.offsetWidth,
                ease: 'power3.out',
                duration: 0.5,
                force3D: true,
            });

            tl.from(this.$refs.links, {
                x: 80,
                autoAlpha: 0,
                ease: 'power3.out',
                stagger: 0.1,
                force3D: true,
            }, 0.2);

            tl.from(this.$refs.mainLinks, {
                autoAlpha: 0,
                ease: 'power3.out',
                force3D: true,
            }, 0.4);

            tl.from(this.$refs.featuredLinks, {
                autoAlpha: 0,
                ease: 'power3.out',
                stagger: 0.1,
            }, 0.5);

            tl.from(this.$refs.languages.children, {
                autoAlpha: 0,
                y: 10,
                ease: 'power3.out',
                stagger: 0.05,
                force3D: true,
            }, 0.45);

            tl.from(this.$refs.head, {
                autoAlpha: 0,
                ease: 'power3.out',
                stagger: 0.1,
            }, 0.5);
        },

        anchorClickHandler(event) {
            const hash = event.target.href.split('#')[1];

            if (!hash) {
                return;
            }

            gsap.to(window, {
                scrollTo: {
                    y: `#${hash}`,
                    autoKill: false,
                },
                duration: 0.4,
                ease: 'power1.inOut'
            });

            this.$emit('close');
        }
    },
}
</script>

<style scoped lang="scss">
.header-menu {
    &__cross-btn {
        background-image: url('~@/static/images/pictos/cross.svg');
    }

    &__nav {
        max-width: 30rem;
    }

    &__list-counter {
        &::after {
            content: '';
            @apply inline-flex w-6 h-px bg-white opacity-20 mx-2;
        }
    }
}
</style>
