<template>
    <section :class="backgroundColor ? 'pt-10 md:pt-18': 'mt-10 md:mt-18'" class="key-figure-switch" :style="`background-color: ${backgroundColor}`">
        <div class="grid-container flex justify-center">
            <Switch :label-left="labels.left" :label-right="labels.right" v-model="isChecked" />
        </div>

        <switch-key-figure-content>
            <div v-if="!isChecked">
                <slot name="left"></slot>
            </div>
            <div v-else>
                <slot name="right"></slot>
            </div>
        </switch-key-figure-content>
    </section>
</template>

<script>
import SwitchKeyFigureContent from "@/app/transitions/SwitchKeyFigureContent";
import Switch from "@/app/components/Switch";

export default {
    name: "KeyFigureSwitch",

    props: {
        labels: {
            type: Object,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: null,
        },
    },

    components: {
        SwitchKeyFigureContent,
        Switch,
    },

    data() {
        return {
            isChecked: false,
        }
    },
}
</script>
