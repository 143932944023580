<template>
    <div class="carrousel-virtual-tour relative w-full">
        <div class="carrousel-virtual-tour__container h-full lg:absolute lg:left-0 lg:inset-y-0">
            <transition name="slideshow-fade" mode="in-out">
                <img
                    :src="currentImage.url"
                    :alt="currentImage.alt"
                    :key="currentImageId"
                    class="absolute w-full h-full inset-0 object-cover object-center"
                >
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "CarrouselVirtualTour",

    props: {
        images: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            currentImageId: 0,
            intervalId: null,
        }
    },

    computed: {
        currentImage() {
            return this.images[this.currentImageId];
        }
    },

    mounted() {
        this.intervalId = setInterval(() => {
            if (this.currentImageId === this.images.length-1) {
                this.currentImageId = 0;
                return;
            }

            this.currentImageId++;
        }, 5000);
    },

    destroyed() {
        clearInterval(this.intervalId);
    },
}
</script>

<style scoped lang="scss">
    .carrousel-virtual-tour {
        height: 100vw;

        @screen lg {
            height: calc(760vw*100/1440);
        }

        &__container {
            width: 100vw;

            @screen lg {
                width: calc(760vw*100/1440);
            }
        }

        .slideshow-fade-enter-active, .slideshow-fade-leave-active {
            transition: opacity 1000ms;
        }
        .slideshow-fade-enter-from, .slideshow-fade-leave-to {
            opacity: 0;
        }
    }
</style>
