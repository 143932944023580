<template>
    <section class="slideout-panel">
        <a :class="customClass" href="#" :aria-label="ariaLabels.open" @click.prevent="togglePanel">{{ text }}</a>

        <transition name="panel-fade" mode="in-out">
            <section v-show="isOpened" class="slideout-panel__panel fixed z-50 inset-0 w-screen h-screen bg-white flex justify-end text-left" @click="togglePanel">
                <div class="slideout-panel__panel-content relative w-full bg-white overflow-y-auto pt-16" @click.stop>
                    <button
                        class="slideout-panel__close-btn absolute z-10 top-4 md:top-10 right-6 md:right-12 w-12 h-12 rounded-full bg-red flex justify-center items-center"
                        :aria-label="ariaLabels.close"
                        @click="togglePanel"
                    ></button>
                    <slot></slot>
                </div>
            </section>
        </transition>
    </section>
</template>

<script>
export default {
    name: "SlideoutPanelLink",

    props: {
        text: {
            type: String,
            required: true,
        },
        customClass: {
            type: String,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isOpened: false,
        }
    },

    methods: {
        togglePanel() {
            this.isOpened = !this.isOpened;
            document.body.style.overflow = this.isOpened ? 'hidden': 'visible';
        },
    },
}
</script>

<style scoped lang="scss">
    .slideout-panel {
        &__panel {
            background-color: rgba(10, 10, 21, 0.5);
        }

        &__panel-content {
            max-width: 65rem;
        }

        &__close-btn {
            &::after {
                @apply w-4 h-4 bg-cover bg-center;
                content: '';
                background-image: url('~@/static/images/pictos/cross.svg');
            }
        }
    }

    .panel-fade-enter-active, .panel-fade-leave-active {
        transition: opacity 300ms;

        .slideout-panel__panel-content {
            transition: transform 300ms;
        }
    }
    .panel-fade-enter-from, .panel-fade-leave-to {
        opacity: 0;

        .slideout-panel__panel-content {
            transform: translateX(200px);
        }
    }
</style>
