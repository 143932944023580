<template>
    <div class="custom-video-player aspect-w-16 aspect-h-9" >
        <iframe
            :src="videoUrl"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script>
export default {
    name: "CustomVideoPlayer",

    props: {
        videoUrl: {
            type: String,
            required: true,
        },
        playAriaLabel: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
.custom-video-player {
    &__play-btn {
        background-image: url('~@/static/images/pictos/play.svg');
    }

    &__iframe-container {
        position: absolute !important;
        width: 100% !important;
        height: auto !important;
    }

    :deep(iframe) {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
