<template>
    <section ref="root" class="section-header h-screen relative overflow-hidden text-white bg-blue-dark">
        <div ref="mainContainer" :class="mainContainerClasses" class="w-full h-screen">
            <slot name="background"></slot>
            <div ref="filet" class="w-px bg-white absolute top-0 left-1/2 opacity-20 z-10"></div>

            <video-background
                class="h-screen"
                :class="{'dark-filter': backgroundVideo.desktop || backgroundVideo.mobile}"
                :src="backgroundVideo.desktop ?? null"
                :sources="[{ src: backgroundVideo.mobile ?? null, res: 767, autoplay: true }]"
            >
                <intersector :threshold="[0.3]" @enter="playIn">
                    <div class="relative h-screen z-10">
                        <div ref="titleContainer" class="h-screen flex flex-col items-center justify-center">
                            <h2 ref="title" class="relative">
                                <span
                                    class="section-header__over-title absolute bottom-full font-medium text-xl uppercase">
                                    <span class="text-red mr-2 md:mr-6">{{ sectionNumber }}</span>
                                    <span>{{ heading1 }}</span>
                                </span>
                                <span class="section-header__title font-bold uppercase">{{ heading2 }}</span>
                            </h2>
                        </div>

                        <div ref="secondaryContent" class="hidden grid-container h-screen flex flex-col justify-end items-center text-center">
                            <div class="grid-row justify-center">
                                <div class="grid-col w-full lg:w-11/15 flex flex-col items-center">
                                    <p ref="text" class="lead" v-html="text"></p>
                                    <div ref="image" class="section-header__image-container mt-40">
                                        <slot name="image"></slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </intersector>
            </video-background>
        </div>
    </section>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";
import Intersector from "@/app/components/Intersector";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: "SectionHeader",

    props: {
        sectionNumber: {
            type: String,
            default: '',
        },
        heading1: {
            type: String,
            default: '',
        },
        heading2: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        backgroundVideo: {
            type: Object,
            default: null,
        },
    },

    components: {
        VideoBackground,
        Intersector,
    },

    data() {
        return {
            isFixed: false,
            isAbove: false,
        }
    },

    computed: {
        mainContainerClasses() {
            if (this.isFixed) {
                return 'fixed top-0 inset-x-0'
            }
            else {
                return this.isAbove ? 'absolute bottom-0 inset-x-0': 'absolute top-0 inset-x-0';
            }
            return null
        }
    },

    mounted() {
        gsap.set(this.$refs.title.children, {
            autoAlpha: 0,
            y: 30,
            force3D: true,
        });

        window.addEventListener('scroll', this.scrollHandler);

        this.animate();
    },

    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        playIn() {
            gsap.to(this.$refs.filet, {
                height: window.innerHeight,
                ease: 'power1.out',
                duration: 2,
                force3D: true,
            });

            gsap.to(this.$refs.title.children, {
                autoAlpha: 1,
                y: 0,
                ease: 'power1.out',
                stagger: 0.5,
                delay: 0.3,
                force3D: true,
            });
        },

        scrollHandler() {
            const boundingClientRect = this.$refs.root.getBoundingClientRect();
            let bottomScroll = boundingClientRect.bottom - window.innerHeight;
            this.isFixed = boundingClientRect.top < 0 && bottomScroll > 0;
            this.isAbove = bottomScroll < 0
        },

        animate() {
            const tl = new gsap.timeline({paused: true});

            tl.to(this.$refs.titleContainer, {
                autoAlpha: 0,
                y: -50,
                force3D: true,
            }).set(this.$refs.titleContainer, {
                display: 'none',
            }).set(this.$refs.secondaryContent, {
                display: 'flex',
            }).from(this.$refs.text, {
                autoAlpha: 0,
                y: 50,
                force3D: true,
            }).from(this.$refs.image, {
                autoAlpha: 0,
                y: 50,
                force3D: true,
            });

            ScrollTrigger.create({
                trigger: this.$refs.root,
                animation: tl,
                markers: false,
                start: "top+=200px top",
                end: "bottom-=200px bottom",
                scrub: 0.4,
            });
        }
    },
}
</script>

<style scoped lang="scss">
.section-header {
    height: 200vh;

    &__over-title {
        left: -2.5rem;
        font-size: 1.25rem;

        @screen md {
            left: -6.25rem;
            font-size: 2.5rem;
        }
    }

    &__title {
        font-size: 2.625rem;

        @screen md {
            font-size: 5rem;
        }
    }

    &__image-container {
        width: 208px;
        height: 208px;

        @screen lg {
            width: auto;
            height: auto;
        }
    }
}
</style>
