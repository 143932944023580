<template>
    <section
        :class="backgroundColor ? 'py-10 md:pt-18 md:pb-32': 'my-10 md:mt-18 md:mb-32'"
        class="carrousel-and-text"
        :style="`background-color: ${backgroundColor};`"
    >
        <div class="grid-container">
            <div :class="{'xl:flex-row-reverse': !isTextFirst}" class="grid-row md:justify-between xl:justify-around">
                <div class="grid-col w-full md:w-7/15 xl:w-6/15">
                    <slot></slot>
                </div>
                <div class="grid-col w-full md:w-7/15 xl:w-6/15 mt-6 xl:mt-0">
                    <carousel :settings="settings">
                        <slide v-for="(item, key) in images" :key="key">
                            <img :src="item.image.url" :alt="item.image.alt">
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';

export default {
    name: "CarrouselAndText",

    props: {
        images: {
            type: Array,
            required: true,
        },
        isTextFirst: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: null,
        },
    },

    components: {
        Carousel,
        Slide,
    },

    data() {
        return {
            settings: {
                autoplay: 4000,
                itemsToShow: 1,
                wrapAround: true,
            }
        }
    },
}
</script>

<style scoped>

</style>
