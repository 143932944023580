<template>
    <intersector :threshold="[0.3]" @enter="animate">
        <section ref="root" class="knots-animation relative mt-25 md:mt-32">
            <slot></slot>

            <div class="
                knots-animation__value-container
                absolute top-0 right-1/2 transform -translate-y-3/4 md:-translate-y-1/2 translate-x-1/2
                flex items-center justify-center
                w-full lg:w-auto px-4 lg:px-0
            ">
                <span class="knots-animation__value font-light text-red mr-2 md:mr-4 w-20 md:w-44 text-right">{{ speed.value }}</span>

                <div class="flex flex-col">
                    <span class="overline text-red">{{ unit }}</span>
                    <span class="overline text-gray-48 mt-1 md:mt-2">{{ text }}</span>
                </div>
            </div>
        </section>
    </intersector>
</template>

<script>
import {gsap} from "gsap";
import Intersector from "@/app/components/Intersector";

export default {
    name: "KnotsAnimation",

    props: {
        maxSpeed: {
            type: Number,
            required: true,
        },
        unit: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },

    components: {
        Intersector,
    },

    data() {
        return {
            speed: {
                value: 0,
            },
        }
    },

    methods: {
        animate() {
            gsap.to(this.speed, {
                value: this.maxSpeed,
                roundProps: "value",
                ease: 'power1.in',
                duration: 2,
            });
        },
    },
}
</script>

<style scoped lang="scss">
    .knots-animation {
        height: calc(600vw*100/1440);

        &__value-container {
            @screen md {
                margin-right: 4rem;
            }
        }

        &__value {
            font-size: 4rem;

            @screen md {
                font-size: 8.5rem;
            }
        }
    }
</style>
